import * as Sentry from "@sentry/browser";

Sentry.init({
	dsn: "https://dcf68417bac698a78399e4d5bc0e7477@o4507570980388864.ingest.de.sentry.io/4507571451199568",
	environment: window.Bittl.system_status || 'Live',
	allowUrls: [/https?:\/\/((cdn|www|media1|media2)\.)?sport-bittl\.com/],
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			// Additional SDK configuration goes in here, for example:
			maskAllText: false,
			blockAllMedia: true,
		}),
		Sentry.debugIntegration()
		// Sentry.feedbackIntegration({
		// 	// Additional SDK configuration goes in here, for example:
		// 	colorScheme: "system",
		// }),
	],
	// Performance Monitoring
	tracesSampleRate: 0.05, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	// original values:
	// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 0.25, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	tunnel: "/tunnel.php",
	// debug:true,

	beforeSend(event, hint) {
		// debugger;
		const error = hint.originalException;
		// console.error(error);
		if(typeof error === 'string') {
			return event
		}
		if(error.fileName && !error?.fileName?.startsWith(window.location.origin)) {
			return null
		}
		if(error.fileName === "https://static.zdassets.com/ekr/snippet.js") {
			return null;
		}
		if (error?.message?.match(/object XMLHttpRequest/i)) {
			return null;
		}
		return event;
	},

});

window.Sentry = Sentry;
//
// Sentry.captureMessage('Hello, world!');
//
// throw new Error("Sentry Frontend Error");
